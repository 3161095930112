var KEYS = {
    key: 'izodom_cookie'
};

$(document).ready(function () {
    $('#close-btn').click(function () {
        $('#cookie').hide(400);
        localStorage.setItem(KEYS.key, true);
    });

    if (!localStorage.getItem(KEYS.key)) {
        $('#cookie').css('display', 'block');
    }
});