
$(window).on('load', function () {
    let cookieKey = 'modal-download';
    let $modal = $('#modal-download');

    if($('body').hasClass('en')) {
        if(!Cookies.get(cookieKey)) {
            setTimeout(function() {
                $modal.modal({
                    show: true,
                    keyboard: false
                });
            }, 2000);
        }
    }

    $modal.on('click', 'a.btn-default', function() {
        $modal.modal('hide');
    });

    $modal.on('hide.bs.modal', function() {
        Cookies.set(cookieKey, true);
    });

    $('body').css('padding-top', $('.navbar.navbar-izodom').outerHeight(true));
    fig25fig18Marigns();
    if ($(window).width() >= 992) {
        homeElementResize();
        buildersElementResize();
    } else {
        homeElementHeightAuto();
        buildersElementHeightAuto();
    }

    $(window).resize(function () {
        $('.cookie table').css('width', $('.container').outerWidth() - 30);
        fig25fig18Marigns();
        if ($(window).width() >= 992) {
            homeElementResize()
            buildersElementResize();
        }
        else {
            homeElementHeightAuto();
            buildersElementHeightAuto();
        }
    });

    function homeElementResize() {
        $('#benefits').css('height', $('#technology').outerHeight(true) + 22);
        $('#company').css('height', $('#producer').outerHeight(true) * 0.6);
        $('#marocco').css('height', $('#producer').outerHeight(true) * 0.4);
    }

    function homeElementHeightAuto() {
        $('#benefits').css('height', 'auto');
        $('#company').css('height', 'auto');
        $('#marocco').css('height', 'auto');
    }

    function buildersElementResize() {

        var dlaWladz = '#dla-wladz';

        if ($(window).width() >= 1200) {
            $('#dla-wladz-top').css('height', $(dlaWladz).outerHeight(true) * 0.48);
            $('#dla-wladz-middle').css('height', $(dlaWladz).outerHeight(true) * 0.20);

            $('#dla-wladz-bottom').css('height', $(dlaWladz).outerHeight(true) * 0.32);
        }
        else {
            $('#dla-wladz-top').css('height', $(dlaWladz).outerHeight(true) * 0.43);
            $('#dla-wladz-middle').css('height', $(dlaWladz).outerHeight(true) * 0.27);
            $('#dla-wladz-bottom').css('height', $(dlaWladz).outerHeight(true) * 0.30);
        }

            $('#developer-right').css('height', $('#developer-left').outerHeight(true));

    }

    function buildersElementHeightAuto() {
        $('#dla-wladz-top').css('height', 'auto');
        $('#dla-wladz-middle').css('height', 'auto');
        $('#dla-wladz-bottom').css('height', 'auto');

        $('#developer-right').css('height', 'auto');
    }

    function fig25fig18Marigns() {
        //var parentWidth = $(parent).outerWidth(true);
        //var fig25 = $('.bottom-row.fig-25').outerWidth(true);
        //var fig18 = $('.bottom-row.fig-18').outerWidth(true);
        //var figEqual = $('.bottom-row.fig-equal').outerWidth(true);
        //$('.bottom-row.fig-equals').css('margin-left', (-0.5) * (parentWidth - fig25 - fig18 - figEqual));
        //$('.bottom-row.fig-18').css('margin-left', (0.5) * (parentWidth - fig25 - fig18 - figEqual));
    }
});

$(document).ready(function () {

    $("#arrow").click(function () {
        $('html, body').animate({
            scrollTop: $("#benefits").offset().top - 90
        }, 1000);
        event.preventDefault();
    });

});
